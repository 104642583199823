<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-sheet class="pa-8 mb-8 text-center">
      <h1>{{ $t("help") }}</h1>
      <p>
        {{ $t("help-text-1-part-1") }}
        <router-link class="link" to="/group/location">
          {{ $t("here.") }}
        </router-link>
        {{ $t("help-text-1-part-2") }}
        <router-link class="link" to="/group/godparent">
          {{ $t("godparent-system") }}
        </router-link>
        {{ $t("help-text-1-part-3") }}
      </p>
      <p>
        {{ $t("help-text-2") }}
      </p>
    </v-sheet>
    <v-row align="center">
      <v-col
        v-for="organisation in organisations"
        :key="organisation.title"
        xl="6"
        lg="6"
        md="6"
        sm="6"
        cols="12"
      >
        <v-card>
          <v-img v-if="organisation.image" :src="organisation.image"></v-img>
          <v-card-title>{{ organisation.title }}</v-card-title>
          <v-card-subtitle>{{ organisation.subtitle }}</v-card-subtitle>

          <v-card-text>{{ organisation.text }}</v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              color="accent"
              text
              class="float-right"
              :href="organisation.href"
              target="_blank"
            >
              {{ $t("more") }}
              <v-icon class="ml-1">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "help",
  components: {
    Breadcrumbs
  },
  data: () => ({
    organisations: [
      {
        title: "Telefon Seelsorge",
        subtitle: "24 Stunden an 365 Tagen im Jahr",
        text:
          "Per Telefon unter 0800 / 1110111 oder 0800 / 1110222, per Mail und Chat unter online.telefonseelsorge.de.",
        href: "https://www.telefonseelsorge.de/"
      },
      {
        title: "Nummer gegen Kummer",
        subtitle: "Kinder und Jugendtelefon",
        text:
          "In Deutschland per Telefon unter 116 111 erreichbar. Akutelle Beratungszeiten können der Website entnommen werden.",
        href: "https://www.nummergegenkummer.de/"
      }
    ]
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("help"),
          disabled: true
        }
      ];
    }
  }
};
</script>
<style>
.link {
  color: #ffc107 !important;
  font-weight: bold;
  text-decoration: none;
}
</style>
